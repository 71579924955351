<script setup lang="ts">
import 'mapbox-gl/dist/mapbox-gl.css';

import Spinner from '@libero/ui-framework/Spinner/Spinner.vue';
import { classNames } from '@libero/utilities/class-names';
import { ref } from 'vue';

interface Map {
  id: string;
  height?: string;
  isLoading?: boolean;
  isFullScreen?: boolean;
  hasOffset?: boolean;
}

withDefaults(defineProps<Map>(), {
  height: undefined,
});

const container = ref<HTMLElement>();
const popup = ref<HTMLElement>();

defineExpose({
  container,
  popup,
});
</script>

<template>
  <div class="map" :class="classNames({ isFullScreen, hasOffset })" :style="{ height }">
    <div v-if="$slots.top" class="map-actions top full-width">
      <slot name="top" />
    </div>

    <div v-if="$slots['top-left-actions']" class="map-actions top left">
      <slot name="top-left-actions" />
    </div>

    <div v-if="$slots['top-middle-actions']" class="map-actions top middle">
      <slot name="top-middle-actions" />
    </div>

    <div v-if="$slots['top-right-actions']" class="map-actions top right">
      <slot name="top-right-actions" />
    </div>

    <div v-if="$slots['bottom-left-actions']" class="map-actions bottom left">
      <slot name="bottom-left-actions" />
    </div>

    <div v-if="$slots['bottom-middle-actions']" class="map-actions bottom middle">
      <slot name="bottom-middle-actions" />
    </div>

    <div v-if="$slots['bottom-right-actions']" class="map-actions bottom right">
      <slot name="bottom-right-actions" />
    </div>

    <div :id="id" ref="container" class="map-container" />

    <div v-if="isLoading" class="map-loader">
      <Spinner />
    </div>

    <div v-if="$slots['popup']" ref="popup">
      <slot name="popup" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/mapbox/components/Map/Map.scss';
</style>
