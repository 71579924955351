<script setup lang="ts">
import { useMap } from '@libero/citizen-report/modules/map/hooks/useMap';
import Map from '@libero/citizen-report/modules/map/views/segments/Map.vue';
import Title from '@libero/organisms/Title/Title.vue';
import Button from '@libero/ui-framework/Button/Button.vue';
import { classNames } from '@libero/utilities/class-names';
import { useI18n } from 'vue-i18n';
import { RouterView } from 'vue-router';

const { t } = useI18n();

const map = useMap();
</script>

<template>
  <div class="app-layout">
    <div class="app-layout-main">
      <div class="app-layout-header">
        <Title size="sm" :title="t('citizen-report.title')" />
      </div>

      <div class="app-layout-content">
        <RouterView />
      </div>
    </div>

    <div class="app-layout-map" :class="classNames({ isOpen: map.isOpen })">
      <Button
        class="app-layout-map-toggle"
        appearance="select"
        isCentered
        :onClick="map.toggleOpen"
      >
        {{ t('report.close-map') }}
      </Button>

      <Map />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/citizen-report/modules/app/views/layouts/AppLayout/AppLayout.scss';
</style>
