<script setup lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/solid';
import { useMutationObserver } from '@libero/hooks/useMutationObserver';
import { useResizeObserver } from '@libero/hooks/useResizeObserver';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import ConditionalWrapper from '@libero/ui-framework/ConditionalWrapper/ConditionalWrapper.vue';
import Scrollable from '@libero/ui-framework/Scrollable/Scrollable.vue';
import { classNames } from '@libero/utilities/class-names';
import { ComponentPublicInstance, computed, onMounted, onUnmounted, ref } from 'vue';

interface Props {
  flex?: string;
  isVertical?: boolean;
  isScrollable?: boolean;
}

const props = defineProps<Props>();

const scrollable = ref<ComponentPublicInstance>();
const interval = ref<NodeJS.Timeout>();
const hasButtons = ref(false);

const scrollableElement = computed<HTMLElement>(
  () => scrollable.value?.$el?.querySelector?.('.simplebar-content-wrapper'),
);

const handleDown = (direction: 'left' | 'right') => () => {
  if (!scrollable.value?.$el) return;

  interval.value = setInterval(() => {
    scrollableElement.value.scrollBy({
      left: direction === 'left' ? -30 : 30,
      behavior: 'smooth',
    });
  }, 50);
};

const handleUp = () => {
  clearInterval(interval.value);
};

const checkHasButtons = () => {
  if (!props.isScrollable) return;
  if (!scrollable.value?.$el) return;

  hasButtons.value = scrollableElement.value.scrollWidth > scrollableElement.value.clientWidth;
};

useResizeObserver(scrollableElement, checkHasButtons);
useMutationObserver(scrollableElement, checkHasButtons);

onMounted(checkHasButtons);

onUnmounted(() => {
  clearInterval(interval.value);
});
</script>

<template>
  <div class="button-group-wrapper" :class="classNames({ isScrollable })">
    <ConditionalWrapper
      ref="scrollable"
      :component="Scrollable"
      :isWrapped="isScrollable"
      :props="{
        class: 'button-group-scrollable',
        isAbsolute: true,
        shouldHideScrollBars: true,
      }"
    >
      <Cluster class="button-group" :class="classNames({ isVertical })" :gap="0" :flex="flex">
        <slot />
      </Cluster>
    </ConditionalWrapper>

    <ButtonGroup v-if="hasButtons" class="button-group-controls">
      <Button size="sm" appearance="select" :onMousedown="handleDown('left')" :onMouseup="handleUp">
        <template #icon>
          <ChevronLeftIcon />
        </template>
      </Button>

      <Button
        size="sm"
        appearance="select"
        :onMousedown="handleDown('right')"
        :onMouseup="handleUp"
      >
        <template #icon>
          <ChevronRightIcon />
        </template>
      </Button>
    </ButtonGroup>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/ButtonGroup/ButtonGroup.scss';
</style>
