import { useLocalStorage } from '@libero/hooks/useLocalStorage';
import { UseMapbox } from '@libero/mapbox/hooks/useMapbox';
import QueryString from 'qs';
import { reactive } from 'vue';

export interface MapLayer {
  id: string;
  title: string;
  type: 'raster';
  source: {
    type: 'raster';
    tiles: string[];
    tileSize: number;
  };
}

const LAYERS: MapLayer[] = [
  {
    id: 'enexis-wms',
    type: 'raster',
    title: 'Enexis',
    source: {
      type: 'raster',
      tiles: [
        'https://opendata.enexis.nl/geoserver/wms?' +
          QueryString.stringify(
            {
              bbox: '{bbox-epsg-3857}',
              format: 'image/png',
              service: 'WMS',
              version: '1.1.1',
              request: 'GetMap',
              srs: 'EPSG:900913',
              width: '256',
              height: '256',
              transparent: true,
              layers:
                'Enexis_Opendata:asm_e_lv_map_cable,Enexis_Opendata:asm_e_lv_station,Enexis_Opendata:asm_e_lv_conn_cable',
            },
            { encode: false },
          ),
      ],
      tileSize: 256,
    },
  },
  {
    id: 'municipality-wms',
    type: 'raster',
    title: 'Gemeentegebied',
    source: {
      type: 'raster',
      tiles: [
        'https://service.pdok.nl/kadaster/bestuurlijkegebieden/wms/v1_0?' +
          QueryString.stringify(
            {
              bbox: '{bbox-epsg-3857}',
              format: 'image/png',
              service: 'WMS',
              version: '1.1.1',
              request: 'GetMap',
              srs: 'EPSG:3857',
              width: '256',
              height: '256',
              transparent: true,
              layers: 'Gemeentegebied',
            },
            { encode: false },
          ),
      ],
      tileSize: 256,
    },
  },
  {
    id: 'bag-wms',
    type: 'raster',
    title: 'BAG',
    source: {
      type: 'raster',
      tiles: [
        'https://service.pdok.nl/lv/bag/wms/v2_0?' +
          QueryString.stringify(
            {
              bbox: '{bbox-epsg-3857}',
              format: 'image/png',
              service: 'WMS',
              version: '1.1.1',
              request: 'GetMap',
              srs: 'EPSG:3857',
              width: '256',
              height: '256',
              transparent: true,
              layers: 'pand,standplaats,ligplaats,verblijfsobject',
            },
            { encode: false },
          ),
      ],
      tileSize: 256,
    },
  },
];

interface UseStyle {
  layers: string[];
  layersOptions: MapLayer[];
  onUpdateLayers: (value: string[]) => void;
}

export const useLayers = (mapbox: UseMapbox): UseStyle => {
  const [layers, setLayers] = useLocalStorage<string[]>('mapbox-layers', []);

  const registerLayers = async () => {
    LAYERS.forEach((layer) => {
      const layerEnabled = layers.value.includes(layer.id);
      const layerExists = mapbox.map.getLayer(layer.id);
      const sourceExists = mapbox.map.getSource(layer.id);

      if (layerEnabled) {
        if (!layerExists) mapbox.map.addLayer(layer);
      } else {
        if (layerExists) mapbox.map.removeLayer(layer.id);
        if (sourceExists) mapbox.map.removeSource(layer.id);
      }
    });
  };

  const onUpdateLayers = (value: string[]) => {
    layers.value = value;
    setLayers(value);
    registerLayers();
  };

  mapbox.onStyleLoad(registerLayers);

  return reactive({
    layers,
    layersOptions: LAYERS,
    onUpdateLayers,
  });
};
